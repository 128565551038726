@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

#MainPageBannerImage {
  min-height: 650px;
}
.max-w-600 {
  max-width: 600px;
}

.h-170 {
  height: 170px;
}

.h-200 {
  height: 200px;
}

.max-w-1280 {
  max-width: 1280px;
}

.max-w-802 {
  max-width: 802px;
}

.max-w-1300 {
  max-width: 1300px;
}

.min-h-65 {
  min-height: 65px;
}

.min-h-96 {
  min-height: 96px;
}

.min-h-260 {
  min-height: 260px;
}

.min-h-290 {
  min-height: 290px;
}

.min-h-400 {
  min-height: 400px;
}

.min-h-600 {
  min-height: 600px;
}

.pt-5 {
  padding-top: 1.5rem;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.pd-left-20 {
  padding-left: 20%;
}

.pd-left-40 {
  padding-left: 40%;
}

.pd-left-45 {
  padding-left: 45%;
}

.w-95p {
  width: 95%;
}

.border-line {
  height: 0.11rem;
  --tw-text-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-text-opacity));
}

@media (max-width: 767px) {
  .mx-auto-md {
    margin: 0 auto;
  }
  .mmx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .md-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .footer_bottom_2 {
    position: relative;
    margin: 0 auto;
    padding-top: 17px;
    /* left: 100px; */
  }
}

@media (min-width: 1024px) {
  .left-5p {
    left: 5%;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
